.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}