.modal-open {
  overflow: hidden;
  padding-right: 16px;
}

// Modal
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(58, 40, 40, 0.6);
  z-index: 10000;
  overflow-y: auto;
  overflow-x: hidden;

  &.open {
    padding-right: 16px;
  }

  &.in {
    transition: all 0.2s linear;
  }

  .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 20px;
  }
}

.modal-without-padding {
  .mat-dialog-container {
    padding: 0;
    position: relative;
    overflow: unset;
  }
}

.modal-coins-bg {
  .mat-dialog-container {
    background-image: url(~src/assets/svg/modals/coins-background.svg);
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
  }
}
