@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: lato;
  src: url('../fonts/lato/Lato-ThinItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 100;
}
