@import "./_constants.scss";
@import "./flex";

.hint-message {
  $append-margin: 10px;

  border-radius: 3px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  width: 100%;
  min-height: 20px;
  padding: 8px 16px;
  background-color: rgba($primary-blue, 0.05);
  color: #000;

  @extend .flex-center;
  justify-content: space-between;

  &-text {
    width: 80%;
  }

  &-append {
    max-width: 20%;
    justify-content: end !important;
  }

  &-append-left {
    margin-right: $append-margin;
  }

  &-append-right {
    margin-left: $append-margin;
  }

  &-append-bottom {
    margin-top: $append-margin;
  }

  &-append-top {
    margin-bottom: $append-margin;
  }

  &--error {
    background-color: rgba($mat-primary-red, .1);
  }
}

.checkbox-common {
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
    margin: 0 9px 0 0;
  }

  &-text {
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 500;
  }

  &-container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    display: none;
  }

  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    background-color: #fff;
    border: 2px solid $medium-gray;
  }

  &-container input:checked ~ &-checkmark {
    border-color: $primary-blue;
  }

  &-checkmark:after {
    width: 7px;
    height: 4px;
    border: 2px solid $primary-blue;
    border-top: none;
    border-right: none;

    display: block;
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;

    transform: rotate(0deg);
    opacity: 0;
    transition: opacity .2s, transform .2s;
  }

  &-container input:checked ~ &-checkmark:after {
    transform: rotate(-45deg);
    opacity: 1;
  }

  .info-icon {
    width: 18px;
    height: 18px;
    fill: $mat-common-bg;
    margin-left: 6px;

    .dropdown-info {
      min-width: 200px;
      height: 240px;
    }
  }

  .info-icon:hover .dropdown-info {
    display: block;
  }
}

.ui-disabled {
  opacity: .5;
  pointer-events: none;
}