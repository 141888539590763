.technical-text {
  .article-title, // common for all technical text pages like 'Terms and Conditions', 'Privacy Policy' etc.
  .dowload-link, // additional
  .dowload-link a, // additional
  app-lang-switch a, // additional,
  .article-subtitle, // common for all
  .article-nav, // common for all,
  .article-text // common for all
  ,
  .article-text p,
  .article-text span,
  .article-text a,
  h1, h2, h3, h4, h5, h6 {
    font-family: Inter, sans-serif !important;
  }

  .article-text,
  .article-text li,
  .article-text p,
  .article-text span,
  .article-text a,
  .article-nav li,
  .article-nav a,
  .article-nav {
    font-size: 15px !important;
  }

  .letter-list {
    counter-reset: item;
    list-style: none;
    > li {
      &:before {
        content: counter(item, lower-alpha) ")";
        counter-increment: item;
        margin-right: 5px;
        font-weight: bold;
      }
    }
  }
}

.base-section.technical-text {
  padding: 63px 0 40px;
}

// links
.technical-text {
  .article-text {
    a {
      color: rgb(0, 0, 255);
      background-color: transparent;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-variant-position: normal;
      text-decoration-line: underline;
      text-decoration-skip-ink: none;
      vertical-align: baseline;
      white-space-collapse: preserve;
    }
  }
}
