@import '/node_modules/@angular/material/theming';

$font-old: 'Lato', sans-serif;
$font: 'Inter', sans-serif;
$fontSize: 16px;
$fontColor: #2E2E30;
$fontLine: 1.2;

$linkColor: #0f7eff;
$linkColorH: #2b8cfc;

$widthMax: 1160px;
$widthMin: 320px;

$h1Size: 30px;
$h2Size: 26px;
$h3Size: 20px;
$h4Size: 16px;
$h5Size: 14px;
$h6Size: 12px;

$btn_bg: #6b89db;
$btn_color: #edeef0;

$mat-primary: #5e9af0;

// from new design
// Colors
$primary-blue: #2B8CFC;
$secondary-blue: #EBF7FD;
$background-blue: #FBFCFD;
$primary-shadow-blue: #CBEFFF;

$primary-black: $fontColor;
$secondary-black: #3F4042;
$additional-black: #282A2D;

$primary-gray: #717375;
$secondary-gray: #57595C;

$medium-gray: #BEC1C4;
$light-gray: #E8EBED;
$xlight-gray: #F2F4F5;

// Settings
$mat-new-primary: #2B8CFC;
$mat-new-primary-hover: #3996ff;
$mat-black: #2E2E30;
$mat-common-bg: #EBF7FD;
$mat-primary-red: #E1393F;
$mat-primary-red-bg: #FFDEDB;

$button-height: 50px;
$button-border-radius: 66px;

$primary-border-radius: 6px;
$primary-border: 1px dashed $light-gray;

$transition-duration: 300ms;
$primary-transition: $transition-duration linear;

$mat-table-even: #EBF7FD;
$mat-table-odd: #FAFDFF;
$table-text-color: #828a97;
$card-bg: #fff9e2;
$card-footer-bg: #ffebc2;
$font-family: 'Inter', sans-serif;

$alert-success-text: #41A73D;
$alert-success-bg: #E9FACF;

$alert-error-text: $mat-primary-red;
$alert-error-bg: $mat-primary-red-bg;

$notification-success: #E9FACF;
$notification-icon-success: #55C340;

$notification-error: #FADECF;
$notification-icon-error: $mat-primary-red;

$notification-info: #fff2d1;
$notification-info-icon: #fff2d1;

$upload-border-primary: #087AFE;
//$primary: mat-palette($mat-indigo);
//$warn:  mat-palette($mat-red);
//$accent: mat-palette($mat-green, 600);

$sidebar-icon-default: #505050;

$box-shadow: 0 3px 15px rgba(0, 0, 0, 0.16);
