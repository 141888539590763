@import "constants";

@mixin centerX() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin frame($z-index: auto) {
  @include size();
  position: absolute;
  z-index: $z-index;
}

@mixin size($width: 100%, $height: $width) {
  width: $width;
  height: $height;
}

@mixin icon-size($size) {
  width: $size;
  height: $size;
  font-size: $size;
}

@mixin div-arrow-on-icon ($top, $right) {
  position: relative;

  &::before, &::after {
    position: absolute;
    top: $top;
    right: $right;
    overflow: auto;
    content: "";
    width: 20px;
    height: 20px;
    background-color: white;
    z-index: -1;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    box-shadow: -3px -3px 8px rgba(0, 0, 0, 0.06);
  }
  &::after {
    z-index: 1;
    box-shadow: none;
  }
}

@mixin add-transition {
  transition: $primary-transition;
  -webkit-transition: $primary-transition;
  -moz-transition: $primary-transition;
  -o-transition: $primary-transition;
  -ms-transition: $primary-transition;
}

@mixin margin-classes {
  @for $i from 0 through 20 {
    $size: $i * 4;
    $margin: $i * 4px;
    .m-#{$size} {
      margin: $margin;
    }
    .ml-#{$size} {
      margin-left: $margin;
    }
    .mr-#{$size} {
      margin-right: $margin;
    }
    .mt-#{$size} {
      margin-top: $margin !important;
    }
    .mb-#{$size} {
      margin-bottom: $margin !important;
    }
    .mx-#{$size} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my-#{$size} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }

  .m-auto {
    margin: auto;
  }
}

@mixin padding-classes {
  @for $i from 0 through 20 {
    $size: $i * 4;
    $padding: $i * 4px;
    .p-#{$size} {
      padding: $padding;
    }
    .pl-#{$size} {
      padding-left: $padding;
    }
    .pr-#{$size} {
      padding-right: $padding;
    }
    .pt-#{$size} {
      padding-top: $padding;
    }
    .pb-#{$size} {
      padding-bottom: $padding;
    }
    .px-#{$size} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }
    .py-#{$size} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}
