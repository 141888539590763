// @import '@angular/material/theming';
@use '@angular/material' as mat;
@import "constants";

$bh-blue: (
  50 : #f7faff,
  100 : #ebf7fd,
  200 : #d6f1ff,
  300 : #6baffd,
  400 : #3996FF,
  500 : #2b8cfc,
  600 : #1c85ff,
  700 : #087afe,
  800 : #0074fb,
  900 : #006eef,
  A100 : #ffffff,
  A200 : #f3f6ff,
  A400 : #c0d2ff,
  A700 : #a7c0ff,
  contrast: (
    50 : $mat-black,
    100 : $mat-black,
    200 : $mat-black,
    300 : $mat-black,
    400 : $mat-black,
    500 : $light-primary-text,
    600 : $light-primary-text,
    700 : $light-primary-text,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $mat-black,
    A200 : $mat-black,
    A400 : $mat-black,
    A700 : $mat-black,
  )
);

$bh-green: (
  50: #e8f5e9,
  100: #E9FACF,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #55C340,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $mat-black,
    100: $mat-black,
    200: $mat-black,
    300: $mat-black,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $mat-black,
    A200: $mat-black,
    A400: $mat-black,
    A700: $mat-black,
  )
);

$bh-grey: (
  50 : #FFFFFF,
  100 : #F2F4F5,
  200 : #E8EBED,
  300 : #DEE0E1,
  400 : #CED0D3,
  500 : #BEC1C4,
  600 : #AEB2B5,
  700 : #9EA2A7,
  800 : #717375,
  900 : #57595C,
  A100 : #FFFFFF,
  A200 : #FFFFFF,
  A400 : #D3DAE2,
  A700 : #C9CED3,
  contrast: (
    50 : $mat-black,
    100 : $mat-black,
    200 : $mat-black,
    300 : $mat-black,
    400 : $mat-black,
    500 : $mat-black,
    600 : $mat-black,
    700 : $mat-black,
    800 : $light-primary-text,
    900 : $light-primary-text,
    A100 : $mat-black,
    A200 : $mat-black,
    A400 : $mat-black,
    A700 : $mat-black,
  )
);

// Foreground palette for light themes.
$light-theme-foreground-palette: (
  base:              $mat-black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba(black, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         $mat-black,
  hint-text:         $primary-gray,
  secondary-text:    $dark-secondary-text,
  icon:              $mat-black,
  icons:             rgba(black, 0.54),
  text:              $mat-black,
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$custom-typography: mat.define-legacy-typography-config(
  $font-family: $font
);

@include mat.all-legacy-component-typographies($custom-typography);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

$primary: mat.define-palette($bh-blue, 500);
$warn: mat.define-palette(mat.$red-palette);
$accent: mat.define-palette($bh-green, 500);

$custom-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

@include mat.all-legacy-component-themes($custom-theme);

.bh-primary-corner {
  height: $button-height;
  border: none;
  background-color: mat.get-color-from-palette($bh-blue, 500);
  color: mat.get-color-from-palette($bh-blue, A100);
  font-size: $h4Size;
  font-weight: 700;
  border-radius: $primary-border-radius;

  &:hover {
    background-color: mat.get-color-from-palette($bh-blue, 400);
    transition-duration: $transition-duration;
  }

  &:disabled {
    background-color: mat.get-color-from-palette($bh-grey, 200);
    color: mat.get-color-from-palette($bh-grey, 500);
    cursor: not-allowed !important;
  }
}

.bh-primary-round {
  @extend .bh-primary-corner;
  border-radius: $button-border-radius !important;
}

.bh-secondary-corner {
  height: $button-height;
  border: none;
  background-color: mat.get-color-from-palette($bh-blue, 100);
  color: mat.get-color-from-palette($bh-blue, 500);
  font-size: $h5Size;
  font-weight: 500;
  border-radius: $primary-border-radius;

  .mat-button-focus-overlay {
    opacity: 0 !important;
  }

  &:hover {
    background-color: mat.get-color-from-palette($bh-blue, 200);
    transition-duration: $transition-duration;
  }

  &:disabled {
    background-color: mat.get-color-from-palette($bh-grey, 200);
    color: mat.get-color-from-palette($bh-grey, 500);
    cursor: not-allowed !important;
  }
}

.bh-ghost {
  @extend .bh-secondary-corner;
  background-color: unset;

  &:hover {
    background-color: mat.get-color-from-palette($bh-blue, 100);
  }

  &:disabled {
    background-color: unset;
  }
}

.warning-item {
  color: $alert-error-text;
}

.mat-menu-panel {
  min-height: 50px !important;
}
