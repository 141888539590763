/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
ion-app {
  visibility: visible !important;
}

/* Basic CSS for apps built with Ionic */
.flex-center, .hint-message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.pointer-none {
  pointer-events: none;
}

.flex-center, .hint-message {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.hint-message {
  border-radius: 3px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-family: Inter, sans-serif;
  width: 100%;
  min-height: 20px;
  padding: 8px 16px;
  background-color: rgba(43, 140, 252, 0.05);
  color: #000;
  justify-content: space-between;
}
.hint-message-text {
  width: 80%;
}
.hint-message-append {
  max-width: 20%;
  justify-content: end !important;
}
.hint-message-append-left {
  margin-right: 10px;
}
.hint-message-append-right {
  margin-left: 10px;
}
.hint-message-append-bottom {
  margin-top: 10px;
}
.hint-message-append-top {
  margin-bottom: 10px;
}
.hint-message--error {
  background-color: rgba(225, 57, 63, 0.1);
}

.checkbox-common {
  display: flex;
  align-items: center;
}
.checkbox-common input {
  width: 18px;
  height: 18px;
  margin: 0 9px 0 0;
}
.checkbox-common-text {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
}
.checkbox-common-container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 18px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-common-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}
.checkbox-common-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: #fff;
  border: 2px solid #BEC1C4;
}
.checkbox-common-container input:checked ~ .checkbox-common-checkmark {
  border-color: #2B8CFC;
}
.checkbox-common-checkmark:after {
  width: 7px;
  height: 4px;
  border: 2px solid #2B8CFC;
  border-top: none;
  border-right: none;
  display: block;
  content: "";
  position: absolute;
  left: 3px;
  top: 3px;
  transform: rotate(0deg);
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
}
.checkbox-common-container input:checked ~ .checkbox-common-checkmark:after {
  transform: rotate(-45deg);
  opacity: 1;
}
.checkbox-common .info-icon {
  width: 18px;
  height: 18px;
  fill: #EBF7FD;
  margin-left: 6px;
}
.checkbox-common .info-icon .dropdown-info {
  min-width: 200px;
  height: 240px;
}
.checkbox-common .info-icon:hover .dropdown-info {
  display: block;
}

.ui-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.round-content {
  border-radius: 50%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.contain {
  object-fit: contain;
  -o-object-fit: contain;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

.display-block {
  display: block;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-25 {
  margin-top: 25px;
}

/* Optional CSS utils that can be commented out */