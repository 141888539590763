.aligncenter {
  text-align: center;
}

.alignright {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.round-content {
  border-radius: 50%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.contain {
  object-fit: contain;
  -o-object-fit: contain;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.relative {
  position: relative;
}

.display-block {
  display: block;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-25 {
  margin-top: 25px;
}